import { ApolloError } from '@apollo/client';
import getErrorData from './getErrorData';
import { getCidMessage } from './uiMessages';

/**
 * To be used when status code is irrelevant but we want to show custom message and cid message
 * @param {ApolloError} error - ApolloError object
 * @returns {string} - cid message
 */

const getOnlyCidMessage = (error: ApolloError) => {
    const { correlationId } = getErrorData(error);
    return correlationId ? getCidMessage(correlationId) : '';
};
export default getOnlyCidMessage;
