export const vehicleTypeOptions = [
    { value: 1, label: 'Personbil' },
    { value: 16, label: 'Transportbil' },
    { value: 4, label: 'Motorcykel' },
    { value: 64, label: 'Moped' },
    { value: 512, label: 'Snöskoter' },
    { value: 128, label: 'ATV' },
    { value: 32, label: 'Husbil' },
    { value: 2, label: 'Husvagn' },
    { value: 8, label: 'Släpfordon' }
];

export const MAX_TITLE_LEN_BB = 64;
export const MAX_TITLE_LEN_BLOCKET = 60;
export const MAX_EQUIPMENT_ITEM_NAME_LENGTH = 35;
export const MAX_MAKE_LENGTH = 32;
export const MAX_MODEL_LENGTH = 64;
