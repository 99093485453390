import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/types';
import { useImmer } from 'use-immer';
import { ApolloError, useMutation } from '@apollo/client';
import CustomerServiceModal from './components/Modal';
import sendCustomerSupportEmailMutation from './mutations/sendCustomerSupportEmail';

interface State {
    modalOpen: boolean;
    error: ApolloError | undefined;
    responseStatus: boolean;
}

const modalId = 'customerService';

const CustomerService = () => {
    const dispatch = useDispatch();

    const modal = useSelector((state: RootState) => state?.ui?.modal);
    const { code: dealerCode, name: dealerName, products } = useSelector((state: RootState) => state.dealer.dealerApi);
    const [sendCustomerSupportEmail] = useMutation(sendCustomerSupportEmailMutation);
    const hasProductAdminPro = !!products.filter(product => product.code === 'admin_pro')[0] || {};

    const [state, setState] = useImmer<State>({
        modalOpen: false,
        error: undefined,
        responseStatus: false
    });

    const setModalState = useCallback(() => {
        let modalOpen = false;

        if (modal.open && modal.id === modalId) {
            modalOpen = true;
        }

        setState(draft => {
            draft.modalOpen = modalOpen;
        });
    }, [setState, modal]);

    const submitHandler = async formData => {
        try {
            await sendCustomerSupportEmail({
                variables: {
                    data: {
                        username: formData.username,
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        message: formData.message
                    }
                }
            });
            setState(draft => {
                draft.responseStatus = true;
            });
        } catch (e) {
            setState(draft => {
                draft.error = e;
            });
        }
    };

    const helpdeskClick = () => {
        dispatch({ type: '@GENERAL/HELPDESK_CLICK', origin: 'CUSTOMERSERVICE' });
    };

    const toggleModal = () => {
        // Reset customerservice form when closing modal
        if (modal.open) {
            dispatch({ type: '@CUSTOMERSERVICE/CUSTOMERSERVICE_RESET' });
        }
        setState(draft => {
            draft.responseStatus = false;
            draft.error = undefined;
        });
        dispatch({ type: 'MODAL', id: modalId, toggle: !modal.open });
    };

    useEffect(() => {
        setModalState();
    }, [modal, setModalState]);

    return (
        <CustomerServiceModal
            isOpen={state.modalOpen}
            toggleModal={toggleModal}
            handleSubmit={submitHandler}
            responseStatus={state.responseStatus}
            error={state.error}
            modal={modal}
            helpdeskClick={helpdeskClick}
            dealerCode={dealerCode}
            dealerName={dealerName}
            hasAdminPro={hasProductAdminPro}
        />
    );
};

export default CustomerService;
