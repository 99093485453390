import { ApolloError } from '@apollo/client';
import {
    ClientNetworkError,
    CustomServerError,
    formatClientNetworkError,
    formatGraphQLError,
    formatGraphQLServerError,
    formatServerNetworkError,
    GraphQLServerError,
    isClientNetworkError,
    isGraphQLError,
    isGraphqlServerError,
    isNetworkError,
    isServerNetworkError
} from './helpers';

interface ErrorData {
    errorCode: number;
    correlationId: string | undefined;
    uiMessage: string | undefined;
}

const NO_DATA = { errorCode: 0, correlationId: undefined, uiMessage: undefined };

const getErrorData = (e: ApolloError): ErrorData => {
    if (isGraphQLError(e)) {
        return formatGraphQLError(e);
    }

    if (isNetworkError(e)) {
        const { networkError } = e;

        if (isGraphqlServerError(networkError)) {
            return formatGraphQLServerError(networkError as GraphQLServerError);
        }

        if (isServerNetworkError(networkError)) {
            return formatServerNetworkError(networkError as CustomServerError);
        }

        if (isClientNetworkError(networkError)) {
            return formatClientNetworkError(networkError as ClientNetworkError);
        }

        return NO_DATA;
    }

    return NO_DATA;
};

export default getErrorData;
