import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Dropdown, DropdownMenu, DropdownToggle } from '@bytbil/bootstrap-react';

import { RootState } from 'state/types';

import capitalizeFirstLetter from 'core/capitalizeFirstLetter';

import TextTruncate from '../Text/Truncate';
import DealerDropdownItem from './DropdownItem';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    setDealer: (dealerId: number) => void;
    align: 'right' | 'left';
    linkClassName?: string;
    textTruncate?: number;
}

const DealerDropdown = ({
    isOpen,
    toggle,
    textTruncate = 11,
    setDealer,

    align,
    linkClassName = ''
}: Props) => {
    const { name: dealerName, city: dealerCity } = useSelector((state: RootState) => state.dealer.dealerApi) || {};

    const { authenticated: isAuthenticated, dealersData } = useSelector((state: RootState) => state.iua) || {};

    return (
        <Dropdown isOpen={isOpen} toggle={toggle} className="dealerDropdown fs-3 text-white" inNavbar>
            <DropdownToggle
                tag="a"
                className={`nav-link ${linkClassName}`}
                data-toggle="dropdown"
                role="button"
                caret={false}
                data-testid="dropdown-toggle"
            >
                <strong data-hj-suppress className="mr-1">
                    <TextTruncate limit={textTruncate}>{`${dealerCity} ${dealerName}`}</TextTruncate>
                </strong>
                <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'} fs-1`} />
            </DropdownToggle>
            <DropdownMenu right={align === 'right'} className="dropdown-fluid" data-testid="dropdown-menu">
                {isAuthenticated &&
                    dealersData &&
                    dealersData.length > 1 &&
                    dealersData.map(({ id, name, code, city, isActive }) => (
                        <DealerDropdownItem
                            data-hj-suppress
                            setDealer={setDealer}
                            dealerId={id}
                            key={code}
                            className={classNames('text-truncate ', !isActive && 'text-muted')}
                        >
                            <strong>{capitalizeFirstLetter(city)}</strong> {name}
                        </DealerDropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default DealerDropdown;
