import React from 'react';
import classNames from 'classnames';
import { Navbar, NavbarToggler } from '@bytbil/bootstrap-react';

import DealerDropdown from 'components/DealerDropdown';
import Logotype from 'components/Logotype';

import { RootState } from 'state/types';
import { useSelector } from 'react-redux';
import MobileOffcanvas from './MobileOffcanvas';
import MobileHeaderNavigationItems from './Navigation/MobileHeaderNavigationItems';

interface Props {
    toggleOffcanvas: (e: React.SyntheticEvent) => void;
    dealerDropdown: boolean;
    setDealer: (dealerId: number) => void;
    toggleDealerDropdown: () => void;
    offcanvas: boolean;
    logoClick: () => void;
}

const MobileHeader = React.memo((props: Props) => {
    const {
        toggleOffcanvas,
        offcanvas,
        setDealer,
        dealerDropdown,
        toggleDealerDropdown,

        logoClick
    } = props;

    const { authenticated: isAuthenticated, dealers: iuaDealers } = useSelector((state: RootState) => state.iua) || {};

    const shouldRenderDealerDropdown = offcanvas && iuaDealers && iuaDealers.length > 1;

    return (
        // A lot of extra divs here, looks ugly but is needed because otherwise we'll have a bunch of ghost elements or warnings
        <React.Fragment>
            <div className={classNames('fixed-top d-none d-xl-none h-100', offcanvas && 'd-block')}>
                <MobileOffcanvas offcanvas={offcanvas} />
            </div>
            <div className="d-xl-none fixed-top">
                <Navbar
                    tag="header"
                    className={classNames('bg-dark-alt header-main mobile-header px-0')}
                    dark
                    suppressHydrationWarning
                >
                    <div className="container d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center flex-row flex-grow">
                            {shouldRenderDealerDropdown && (
                                <DealerDropdown
                                    isOpen={dealerDropdown}
                                    toggle={toggleDealerDropdown}
                                    setDealer={setDealer}
                                    linkClassName="pl-0"
                                    textTruncate={25}
                                    align="right"
                                />
                            )}
                            <Logotype
                                className={classNames(
                                    offcanvas && iuaDealers && iuaDealers.length === 1 && 'header-logo-above',
                                    shouldRenderDealerDropdown && 'd-none'
                                )}
                                onClick={logoClick}
                            />
                            <div className="flex-grow">
                                {!offcanvas && (
                                    <ul className="d-flex flex-row navbar-nav justify-content-end fs-2 fs-md-4">
                                        <MobileHeaderNavigationItems />
                                    </ul>
                                )}
                            </div>
                        </div>
                        {isAuthenticated && (
                            <NavbarToggler
                                className={classNames(
                                    'flex-last',
                                    'align-self-center',
                                    'navbar-toggler',
                                    'transition',
                                    'd-xl-none',
                                    'pl-2',
                                    'pl-sm-3',
                                    offcanvas && 'active'
                                )}
                                onClick={toggleOffcanvas}
                            />
                        )}
                    </div>
                </Navbar>
            </div>
        </React.Fragment>
    );
});

export default MobileHeader;
