import { Intent } from 'core/pulse/types';
import { pulseTrackerLegacy, pulseTracker } from './pulseTracker';
import pulseUpdate from './pulseUpdate';

interface PulseUIElementClickProps {
    name: string;
    intent: Intent;
    object?: {
        name: string;
    };
}

// this is legacy, convert to pulseTracker
export const pulseClickUIElement = (name: string, custom?: object) => {
    pulseTrackerLegacy({
        action: 'Click',
        object: {
            type: 'UIElement',
            name,
            custom
        }
    });
};

// this is legacy, convert to pulseTracker
export const pulseDragUIElement = (name: string) =>
    pulseTrackerLegacy({
        action: 'Drag',
        object: {
            type: 'UIElement',
            name
        }
    });

export const pulseUIElementClick = ({ name, intent, object }: PulseUIElementClickProps) => {
    pulseTracker({
        name,
        type: 'Click',
        intent,
        object: {
            type: 'UIElement',
            name: object && object.name
        }
    });
};

export const pulseViewFrontPage = () =>
    pulseUpdate({
        object: {
            type: 'Frontpage'
        }
    });

export const pulseViewPage = (name?: string) =>
    pulseUpdate({
        object: {
            type: 'Page',
            name
        }
    });
