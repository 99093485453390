import Cookie from 'js-cookie';
import * as UI from './actionTypes';

export const modal = (id, toggle, origin) => ({
    type: UI.MODAL,
    toggle,
    id,
    origin
});

export const toast = (message, status = 'success', timeout = 5000) => ({
    type: UI.TOAST,
    message,
    timeout,
    status
});

export const setActionPanelOpen = open => ({
    type: UI.ACTION_PANEL,
    open
});

export const showPageLoader = show => ({
    type: UI.PAGELOADER,
    show
});

export const showOffcanvas = show => ({
    type: UI.OFFCANVAS,
    show
});

export const showDealerDropdown = show => ({
    type: UI.DEALERDROPDOWN,
    show
});

export const showOffcanvasDealerDropdown = show => ({
    type: UI.OFFCANVAS_DEALERDROPDOWN,
    show
});

export const showUserDropdown = show => ({
    type: UI.USERDROPDOWN,
    show
});

export const showOffcanvasCollapse = (id, show, isSubsectionTo) => ({
    type: UI.OFFCANVAS_COLLAPSE,
    offcanvasCollapse: {
        id,
        show,
        isSubsectionTo
    }
});

export const showInitialLoader = show => ({
    type: UI.INITIAL_LOADER,
    show
});

export const dismissSchibstedStickyFooter = () => dispatch => {
    if (typeof window !== 'undefined') {
        Cookie.set('hide_schibsted_sticky_footer', true, { expires: 14, domain: window.App.config.cookieDomain });

        try {
            dispatch({
                type: UI.DISMISS_SCHIBSTED_STICKY_FOOTER
            });
        } catch (e) {
            console.warn('Error dismissing Schibsted sticky footer');
        }
    }
};

export const toggleSidePanel = ({ show }) => ({
    type: UI.SIDE_PANEL,
    show
});
