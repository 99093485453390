import { combineReducers } from 'redux';
import * as USER from './actionTypes';

export const defaultAuthState = {
    status: {
        isFetching: false
    },
    error: {
        status: false,
        type: undefined
    },
    authenticated: false
};

export function auth(state = defaultAuthState, action) {
    switch (action.type) {
        case USER.SET_UNAUTHENTICATED:
            return defaultAuthState;

        // Logout user
        case USER.USER_LOGOUT_REQUEST:
            return defaultAuthState;

        // Authenticate user against API
        case USER.USER_LOGIN_REQUEST:
            return {
                ...state,
                status: {
                    isFetching: true
                }
            };

        case USER.USER_LOGIN_RESPONSE:
            return {
                ...state,
                ...defaultAuthState.error,
                state: {
                    isFetching: false
                }
            };

        case USER.USER_LOGIN_ERROR:
            return {
                ...state,
                status: {
                    isFetching: false
                },
                error: {
                    status: true,
                    type: action.error
                }
            };

        default:
            return state;
    }
}

export const defaultDealerState = {};
export function dealer(state = defaultDealerState, action) {
    switch (action.type) {
        case USER.SET_DEALER:
            return {
                ...state,
                id: action.dealerId
            };
        default:
            return state;
    }
}

export const defaultDetailsState = {};
export function details(state = defaultDetailsState, action) {
    switch (action.type) {
        // Set user details from object
        case USER.SET_USER_OBJECT:
            return {
                ...state,
                ...action.payload
            };

        // Set user details from cookie
        case USER.SET_USER_FROM_COOKIE:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default combineReducers({
    details,
    dealer,
    auth
});
