export const ADMIN_USER = 1;
export const BOOST_LEVEL_PREMIUM = 20;
export const PREMIUM_AD_PRICE = 150;
export const PREMIUM_AD_PRICE_NEW = 164;

export const STATUS_CODE = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    TOO_MANY_REQUESTS: 429,
    SERVICE_UNAVAILABLE: 503
};
