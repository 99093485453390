import React from 'react';

import NavigationItem from 'components/Navigation/NavigationItem';

import ComposeNavigationItems from './ComposeNavigationItems';

interface Props {
    className?: string;
}

const StockNavigationItems = React.memo(({ className }: Props) => (
    <ComposeNavigationItems origin="header">
        {({ onNavigationClick }) => (
            <React.Fragment>
                <NavigationItem to="/vehicles" id="vehicles" onClick={onNavigationClick} className={className}>
                    Annonser
                </NavigationItem>

                <NavigationItem
                    to="/vehicles/history"
                    id="vehicles-history"
                    onClick={onNavigationClick}
                    className={className}
                >
                    Annonshistorik
                </NavigationItem>

                <NavigationItem to="/bumpsettings" id="bumpsettings" onClick={onNavigationClick} className={className}>
                    Smart annonsförnyelse
                </NavigationItem>
                <NavigationItem to="/prices" id="prices" onClick={onNavigationClick} className={className}>
                    Ändra priser
                </NavigationItem>

                <NavigationItem id="regnos" to="/edit-regno" onClick={onNavigationClick} className={className}>
                    Ändra reg.nr
                </NavigationItem>

                <NavigationItem id="importstatus" to="/import-status" onClick={onNavigationClick} className={className}>
                    Importstatus
                </NavigationItem>
            </React.Fragment>
        )}
    </ComposeNavigationItems>
));

export default StockNavigationItems;
