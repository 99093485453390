import * as IUA_USER from 'state/iua/actionTypes';

import customHistory from '../customHistory';

/**
 * Middleware that redirects the user to the redirectUrl after the user logs in
 */
const redirectMiddleware = store => next => action => {
    if (action.type === IUA_USER.SET_AUTHENTICATED) {
        const state = store.getState();
        const redirectUrl = state.iua?.redirectUrl;

        if (redirectUrl && customHistory) {
            // Navigate to the redirectUrl
            customHistory.push(redirectUrl);

            store.dispatch({ type: IUA_USER.CLEAR_REDIRECT_URL });
        }
    }

    // Pass the action to the next middleware or the reducer
    return next(action);
};

export default redirectMiddleware;
