import { createSelector } from 'reselect';

export const getUser = state => state.user.details;
export const getDealerId = state => state.user.dealer.id;

const groupMembers = state => state.dealer.dealerApi.groupMembers;

export const getDealer = createSelector([groupMembers, getDealerId], (dealers, dealerId) => {
    let selectedDealer;
    dealers.forEach(dealer => {
        if (dealer.id === dealerId) {
            selectedDealer = dealer;
        }
    });
    return selectedDealer;
});
