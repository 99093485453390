export const getCidMessage = (correlationId: string | undefined) =>
    correlationId ? `Uppge felkod [${correlationId.slice(0, 8)}] till kundservice om problemet kvarstår.` : '';

export const LOGIN = {
    BAD_CREDENTIALS: 'E-posten eller lösenordet var felaktigt. ',
    FAILED: 'Inloggningen misslyckades. '
};

export const VERIFY_USER = {
    FAILED: 'Hoppsan! Något blev inte riktigt rätt. Länken är bara giltig i 24 timmar eller så har den redan använts. '
};

export const CREATE_USER = {
    CONFLICT: 'Eposten är redan registrerad. ',
    INVALID_INPUT: 'Något av fälten saknas eller är felaktigt. '
};

export const RESEND_INVITE = {
    NOT_FOUND: 'Hittade ingen aktiv inbjudan för denna användare. Vänligen kontakta support. '
};

export const VALIDATE_EMAIL_UPDATE = {
    NOT_FOUND: 'Något gick fel, länken är förmodligen inte aktiv längre. '
};

export const INVITE_USER = {
    CONFLICT: 'Det finns redan en användare med denna epost. '
};

export const RESET_PASSWORD = {
    NOT_FOUND:
        'Om det finns ett konto kopplat till den e-postadress du angav så skickas instruktioner för hur du återställer ditt lösenord.'
};

export const BUMP_FILTER = {
    CONFLICT: 'Det finns redan ett filter med detta namn. '
};

export const BOOMP = {
    TOO_MANY_REQUESTS: 'Annonsen har redan förnyats den senaste timmen och kommer inte att förnyas igen. '
};

export const BOOST = {
    FAILED: 'Annonsen kunde tyvärr inte bli premium. Kontakta kundservice för mer info. '
};

export const SUBMIT_OFFER = {
    CONFLICT:
        'Registreringsnumret används redan i en annan annons. Ange ett annat registreringsnummer och försök igen. '
};

export const CAR_INFO = {
    NOT_FOUND: 'Ingen data hittades för det angivna registreringsnumret. '
};

export const NAME = {
    LOGIN: 'login',
    VERIFY_USER: 'verifyUser',
    CREATE_USER: 'createUser',
    RESEND_INVITE: 'resendInvite',
    VALIDATE_EMAIL_UPDATE: 'validateEmailUpdate',
    INVITE_USER: 'inviteUser',
    RESET_PASSWORD: 'resetPassword',
    BUMP_FILTER: 'bumpFilter',
    BOOMP: 'boomp',
    BOOST: 'boost',
    SUBMIT_OFFER: 'submitOffer',
    CAR_INFO: 'carInfo'
};

export const UNIQUE_DEFAULT_MESSAGES = [LOGIN.FAILED, VERIFY_USER.FAILED, BOOST.FAILED];
