import { combineReducers } from 'redux';

/* Global reducers */
import user from './user/reducer';
import dealer from './dealer/reducer';
import ui from './ui/reducer';
import featureToggle from './feature-toggle/reducer';
import connections from './connections/reducer';
import dcb from './dcb/reducer';
import iua from './iua/reducer';

/* Feature reducers */
import statistics from '../features/statistics/state/reducer';
import dashboard from '../features/dashboard/state/reducer';
import boomp from '../features/boomp/state/reducer';
import bumpRecommendations from '../features/bumpRecommendations/state/reducer';
import pricing from '../features/pricing/state/reducer';
import offer from '../features/offer/state/reducer';
import activeAds from '../features/activeads/state/reducer';
import vehiclesHistory from '../features/vehicles/history/state/reducer';
import equipmentGroup from '../features/equipmentlists/state/reducer';

/* Component reducers */
import sidePanel from '../components/SidePanel/state/reducer';

/* Action types */
import { actionTypes as USER } from './user';

const rootReducer = combineReducers({
    user,
    dashboard,
    dealer,
    connections,
    dcb,
    iua,
    statistics,
    ui,
    featureToggle,
    offer,
    boomp,
    bumpRecommendations,
    pricing,
    activeAds,
    vehiclesHistory,
    sidePanel,
    equipmentGroup
});

export default (state, action) => {
    if (action.type === USER.USER_LOGOUT_REQUEST) {
        const resetState = {
            featureToggle: state.featureToggle
        };
        return rootReducer(resetState, action);
    }

    return rootReducer(state, action);
};
