import { ApolloError, ServerError } from '@apollo/client';
import { ERROR_MESSAGE } from 'core/constants/common';
import { UNIQUE_DEFAULT_MESSAGES } from './uiMessages';

export interface GraphQLServerError extends ServerError {
    statusCode: number;
    result: {
        errors: {
            extensions: {
                correlationId: string;
                errorCode: number;
                uiMessage?: string;
            };
        }[];
    };
}

export interface ClientNetworkError extends Error {
    result: {
        correlationId: string;
        errorCode: number;
    };
}

export interface CustomServerError extends ServerError {
    result: {
        correlationId: string;
        errorCode: number;
        statusCode: number;
        uiMessage?: string;
    };
}

export const isGraphQLError = (e: ApolloError) => !!e?.graphQLErrors?.length;
export const isNetworkError = (e: ApolloError) => !!e?.networkError;

export const isClientNetworkError = (error: any): error is ClientNetworkError =>
    error?.result && 'correlationId' in error.result && !('statusCode' in error.result);
export const isServerNetworkError = (error: any): error is CustomServerError =>
    error?.result && 'correlationId' in error.result && 'statusCode' in error.result;
export const isGraphqlServerError = (error: any): error is GraphQLServerError =>
    error?.result && 'errors' in error.result;

export const formatGraphQLError = (e: ApolloError) => {
    const data = e.graphQLErrors[0]?.extensions;
    return {
        errorCode: data?.errorCode,
        correlationId: data?.correlationId,
        uiMessage: data?.uiMessage
    };
};
export const formatGraphQLServerError = (e: GraphQLServerError) => {
    const data = e.result.errors[0]?.extensions;
    return {
        errorCode: data?.errorCode || e.statusCode,
        correlationId: data?.correlationId,
        uiMessage: data?.uiMessage
    };
};
export const formatServerNetworkError = (e: CustomServerError) => {
    const { result } = e;
    return {
        errorCode: result?.errorCode || e.statusCode,
        correlationId: result?.correlationId,
        uiMessage: result?.uiMessage
    };
};
export const formatClientNetworkError = (e: ClientNetworkError) => {
    const { result } = e;
    return {
        errorCode: result?.errorCode,
        correlationId: result?.correlationId,
        uiMessage: undefined
    };
};

export const getShouldShowCid = (uiMessage: string) =>
    uiMessage === ERROR_MESSAGE.COMMON || UNIQUE_DEFAULT_MESSAGES.includes(uiMessage);
