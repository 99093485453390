import gql from 'graphql-tag';

export default gql`
    query HandleDealerImpersonation($token: String!) {
        handleDealerImpersonation(token: $token) {
            session_id
            checksum
        }
    }
`;
