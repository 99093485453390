import { createSelector } from 'reselect';
import { RootState, Tag } from 'state/types';

export const getDealer = (state: RootState) => state.dealer;

export const getDealerApiData = (state: RootState) => state.dealer.dealerApi;

const hasTag = (tags: Tag[], tag: string) => tags.some(tagObj => tagObj.tag === tag);

export const getDealerId = createSelector([getDealerApiData], data => (data ? data.id : undefined));
const getDealerName = createSelector([getDealerApiData], data => (data ? data.name : undefined));
export const getDealerCode = createSelector([getDealerApiData], data => (data ? data.code : undefined));

export const getDealerTags = createSelector([getDealerApiData], dealerApi => (dealerApi ? dealerApi.tags : undefined));
export const getDealerProducts = createSelector([getDealerApiData], dealerApi =>
    dealerApi ? dealerApi.products : undefined
);
export const getDealerGroups = createSelector([getDealerApiData], dealerApi =>
    dealerApi ? dealerApi.groups : undefined
);

export const getDealerInfo = createSelector([getDealerId, getDealerName, getDealerCode], (id, name, code) => ({
    id,
    name,
    code
}));

export const getDealerHasTag = (tag: string) =>
    createSelector([getDealerApiData], ({ tags }) => (tags ? hasTag(tags, tag) : false));

export const getDealerGroupMembers = createSelector([getDealerApiData], dealerApi => dealerApi.groupMembers || []);
