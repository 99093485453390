import { createSelector } from 'reselect';

import { RootState } from 'state/types';

export const getIsAuthenticated = (state: RootState) => state.iua.authenticated;
export const getUser = (state: RootState) => state.iua;
export const getActiveDealersData = (state: RootState) => state.iua.dealersData.filter(dealer => dealer?.isActive);

export const getActiveDealerCodes = createSelector([getActiveDealersData], activeDealersData =>
    activeDealersData.map(dealer => dealer?.code).join()
);
export const getActiveDealerIds = createSelector([getActiveDealersData], activeDealersData =>
    activeDealersData.map(dealer => dealer?.id).join()
);

export const getDealers = state => state.dealer.dealerApi.groupMembers;

export const getDealerById = id =>
    createSelector([getDealers], dealerItems => dealerItems.find(item => item.id === id));
