// https://pulse.schibsted.io/tracking/start/schema-browser
import { Intent } from 'core/pulse/types';

interface Props {
    action: 'Click' | 'Zoom' | 'Scroll' | 'Drag';
    target?: {
        type: 'Application';
    };
    object: {
        type?: 'UIElement';
        /** object.name should be something unique that identifies this event */
        name: string;
        custom?: object;
    };
}

interface PulseTrackerProps {
    type: string;
    name: string;
    intent: Intent;
    object: {
        type: string;
        name?: string;
        elementType?: string;
    };
}

export const pulseTrackerLegacy = ({
    action,
    target: { type: targetType = 'Application' } = { type: 'Application' },
    object
}: Props) => {
    pulse(tracker => {
        tracker.track('engagementEvent', {
            action,
            target: {
                '@id': 'sdrn:schibsted:application:bytbilse',
                '@type': targetType
            },
            object: {
                '@type': object.type,
                name: object.name,
                'spt:custom': object.custom
            }
        });
    });
};

export const pulseTracker = ({ type, name, intent, object }: PulseTrackerProps) => {
    pulse(tracker => {
        tracker.track('UIElement', {
            name,
            type,
            object,
            intent
        });
    });
};
