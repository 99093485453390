import * as IUA_USER from './actionTypes';

export const defaultAuthState = {
    authenticated: false,
    dealers: [],
    dealersData: [],
    permissions: [],
    user: {},
    checksum: '',
    feedbackMessage: null,
    redirectUrl: null
};

export function iua(state = defaultAuthState, action) {
    switch (action.type) {
        // Set user authenticated / unauthenticated
        case IUA_USER.SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                permissions: action.data.permissions,
                dealers: action.data.dealers,
                dealersData: action.data.dealersData,
                user: action.data.user,
                roles: action.data.roles
            };

        case IUA_USER.SET_UNAUTHENTICATED:
            return {
                ...defaultAuthState,
                feedbackMessage: action.payload?.feedbackMessage || null,
                redirectUrl: action.payload?.redirectUrl || null
            };

        case IUA_USER.SET_USER_DATA:
            return {
                ...state,
                user: action.data
            };

        case IUA_USER.UPDATE_CHECKSUM:
            return {
                ...state,
                checksum: action.checksum
            };

        case IUA_USER.CLEAR_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: null
            };

        default:
            return state;
    }
}

export default iua;
