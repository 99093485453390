import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/types';
import { actions as uiActions, selectors as uiSelectors } from 'state/ui';

import CollapseItem from './CollapseItem';

interface Props {
    id: string;
    active?: boolean;
    isPublic?: boolean;

    children: React.ReactNode[];
    isSubsectionTo?: string;
}

const SubNavigationItems = ({
    active = false,
    isPublic = false,

    isSubsectionTo = undefined,
    id,

    children
}: Props) => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state: RootState) => state.iua.authenticated) || {};
    const offcanvasCollapse = useSelector((state: RootState) => uiSelectors.getOffcanvasCollapse(state));

    const showOffcanvasCollapse = (toggleId, show) => {
        dispatch(uiActions.showOffcanvasCollapse(toggleId, show, isSubsectionTo));
    };

    if (!isPublic && !isAuthenticated) {
        return null;
    }

    return (
        <div className="nav-item-parent" key={`collapse-item-${id}`}>
            <CollapseItem
                id={id}
                active={active}
                toggleOffcanvasCollapse={showOffcanvasCollapse}
                offcanvasCollapse={offcanvasCollapse}
                isSubsectionTo={isSubsectionTo}
            >
                {children}
            </CollapseItem>
        </div>
    );
};

// eslint-disable-next-line react/prop-types
SubNavigationItems.Toggle = ({ children, ...props }) => (
    <CollapseItem.Toggle {...props}>{children}</CollapseItem.Toggle>
);

// eslint-disable-next-line react/prop-types
SubNavigationItems.Items = ({ children, ...props }) => <CollapseItem.Items {...props}>{children}</CollapseItem.Items>;

export default SubNavigationItems;
