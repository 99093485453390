import React, { ReactNode } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import customHistory from './customHistory';

interface CustomRouterProps {
    children: ReactNode;
}

const CustomRouter = ({ children }: CustomRouterProps) => (
    <HistoryRouter history={customHistory as any}>{children}</HistoryRouter>
);

export default CustomRouter;
