import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/types';
import { getActiveDealerCodes } from 'state/iua/selectors';

import hasProduct from 'core/hasProduct';

import NewDCBOrders from './NewDCBOrders';

// Right now we are just sending notifications for new DCB orders but who knows
// what the future will have in store...
const SpringNotifications = () => {
    const { products } = useSelector((state: RootState) => state.dealer.dealerApi);
    const dealerCodes = useSelector(getActiveDealerCodes);

    if (dealerCodes && hasProduct({ products, productCode: 'dcb' })) {
        return <NewDCBOrders dealerCode={dealerCodes} />;
    }
    return null;
};

export default SpringNotifications;
