import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { actions as iuaActions } from 'state/iua';

import HANDLE_DEALER_IMPERSONATION from 'graphql/handleDealerImpersonation';

const ImpersonationHandlerContent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Extract token from the URL query parameters
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const { data, loading, error } = useQuery(HANDLE_DEALER_IMPERSONATION, {
        variables: { token },
        onCompleted: async () => {
            if (data?.handleDealerImpersonation?.session_id) {
                // Dispatch checksum update to Redux
                await dispatch(iuaActions.updateChecksum(data.handleDealerImpersonation.checksum));

                window.location.href = '/'; // Reload the page
            } else {
                navigate('/'); // Redirect to login page
            }
        }
    });

    useEffect(() => {
        if (loading) return; // Wait for session check to complete
        if (error) {
            navigate('/'); // Redirect to login page
        }
    }, [loading, error, navigate]);

    return null;
};

const ImpersonationHandler = () => {
    const [isClient, setIsClient] = useState(false); // Track if it's client-side

    useEffect(() => {
        setIsClient(true); // Set isClient to true when mounted in the browser
    }, []);

    if (!isClient) {
        return null; // Don't render anything on the server
    }

    return <ImpersonationHandlerContent />;
};

export default ImpersonationHandler;
