import * as SIDE_PANEL from './actionTypes';

const defaultState = {
    offer: {
        adId: null,
        blocketUrl: null,
        bytbilUrl: null
    },
    sidepanelTab: 'offer'
};

const sidePanelReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SIDE_PANEL.SET_OFFER_PROPS:
            return {
                ...state,
                offer: {
                    ...action.vehicle,
                    adId: action.adId,
                    blocketUrl: action.blocketUrl,
                    bytbilUrl: action.bytbilUrl
                }
            };
        case SIDE_PANEL.RESET_OFFER_PROPS:
            return {
                ...state,
                ...defaultState
            };
        case SIDE_PANEL.SET_OFFER_TAB: {
            return {
                ...state,
                sidepanelTab: action.sidepanelTab
            };
        }
        default:
            return state;
    }
};

export default sidePanelReducer;
