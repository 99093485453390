import React from 'react';
import { Container } from '@bytbil/bootstrap-react';

const SchibstedPermanentFooter = () => (
    <div className="d-flex w-100 justify-content-center align-items-center schibsted-permanent-footer">
        <Container>
            <div className="d-flex w-100 justify-content-between align-items-center content-wrapper">
                <div className="d-flex align-items-baseline white-space-xl-nowrap">
                    <div className="d-none d-xl-flex justify-content-xl-between align-items-xl-baseline">
                        <p className="my-0 mr-1 ml-4 text">Blocket Admin är en del av Schibsted Marketplaces.</p>
                    </div>
                    <p className="my-0 text">
                        Schibsted News Media AB och Schibsted ASA är ansvariga för dina data på denna webbplats.
                        <a
                            className="ml-2 read-more-link"
                            href="https://info.privacy.schibsted.com/se/personuppgifts-och-kakpolicy-schibsted-marketplaces-sverige/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Läs mer här
                            <i className="fa fa-external-link ml-1" />
                        </a>
                    </p>
                </div>
            </div>
        </Container>
    </div>
);

export default SchibstedPermanentFooter;
