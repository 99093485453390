import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import apolloLogger from 'apollo-link-logger';

import { RootState } from 'state/types';

import customFetch from './customFetch';
import createCache from './createCache';
import createErrorLink from './createErrorLink';

interface WindowAppConfig {
    IS_DEV?: boolean;
}

declare global {
    interface Window {
        App?: {
            config?: WindowAppConfig;
            apolloData?: NormalizedCacheObject;
        };
    }
}

const cache = createCache();

export default function createApolloClient(store: RootState): ApolloClient<NormalizedCacheObject> {
    const errorLink = createErrorLink(store);

    const apolloLinkOptions: ApolloLink[] = [
        errorLink,
        new ApolloLinkTimeout(30000),
        createUploadLink({
            uri: `/graphql`,
            credentials: 'include',
            headers: { 'Apollo-Require-Preflight': 'true' },
            fetch: (url, options) => {
                // Use customFetch only for file uploads that require upload progress
                if (options.body instanceof FormData && options.onProgress) {
                    return customFetch(url, options);
                }

                return fetch(url, options);
            }
        })
    ];

    // Add logger in development mode
    if (typeof window !== 'undefined' && window.App?.config?.IS_DEV) {
        apolloLinkOptions.unshift(apolloLogger);
    }

    return new ApolloClient({
        link: ApolloLink.from(apolloLinkOptions),
        cache: cache.restore(window.App?.apolloData || ({} as NormalizedCacheObject)),
        queryDeduplication: true
    });
}
