import React from 'react';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import { timeout } from 'promise-timeout';

import { GlobalContextProps } from 'state/types';

import useIsClient from 'core/hooks/useIsClient';

import ManageScroll from './ManageScroll';
import GlobalContextComponent from '../context/GlobalContext';

import Authenticated from './Authenticated';
import PageViewComponent from './PageViewComponent';
import Layout from './Layout';
import LoggedOutLayout from './Layout/LoggedOutLayout';
import SentryFallbackComponent from './SentryFallbackComponent';
import ImpersonationHandler from './ImpersonationHandler';

/**
 * Remove promiseMinDelay.js and delay.js after razzle babels node_modules
 * We can then use https://github.com/sindresorhus/p-min-delay again
 * */
import pMinDelay from '../core/promiseMinDelay';
import SessionChangedReloadBanner from './SessionChangedReloadBanner';

const MIN_DELAY = typeof window !== 'undefined' ? 200 : 0;
const MAX_DELAY = 2000;

const loadBoundaries = mod => timeout(pMinDelay(mod, MIN_DELAY), MAX_DELAY);

const Login = loadable(() => loadBoundaries(import('../routes/login')));

const CompleteInviteSignup = loadable(() => loadBoundaries(import('../routes/iua/invite')));
const VerifyUser = loadable(() => loadBoundaries(import('../routes/iua/verify')));
const NewPassword = loadable(() => loadBoundaries(import('../routes/iua/newPassword')));
const UpdateEmail = loadable(() => loadBoundaries(import('../routes/iua/updateEmail')));

const sentryFallback = <SentryFallbackComponent />;

const onSentryError = (error, errorInfo) => {
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
};

interface Props {
    context: GlobalContextProps;
}

interface ChildrenProps {
    children: React.ReactNode;
}

const HelmetAndGlobalContext = ({ context, children }: Props & ChildrenProps) => (
    <>
        <Helmet titleTemplate="%s | Blocket Fordon administration" />
        <GlobalContextComponent context={context}>{children}</GlobalContextComponent>
    </>
);

const renderNotAuthenticatedBasedOnPath = location => {
    if (!location || !location.pathname) {
        return null;
    }

    if (location.pathname.includes('/impersonate/dealer')) {
        return <ImpersonationHandler />;
    }

    // Should really move this into <Routes> below as a proper Route
    if (location.pathname.includes('/users/invite/')) {
        return <CompleteInviteSignup code={location.pathname.replace('/users/invite/', '')} />;
    }

    return (
        <LoggedOutLayout>
            <div className="main-container">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/password/:token" element={<NewPassword />} />
                    <Route path="/user/update-email/:token" element={<UpdateEmail />} />
                    <Route path="/users/verify/:token" element={<VerifyUser />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </div>
        </LoggedOutLayout>
    );
};

const HasLoggedInSession = ({ context }: Props) => {
    const isClient = useIsClient();
    const location = useLocation();

    if (location.pathname.includes('/impersonate/dealer')) {
        return <ImpersonationHandler />;
    }

    return (
        <Sentry.ErrorBoundary fallback={sentryFallback} onError={onSentryError}>
            {isClient && (
                <HelmetAndGlobalContext context={context}>
                    <>
                        <SessionChangedReloadBanner />
                        <Layout>
                            <Authenticated />

                            <PageViewComponent />
                            <ManageScroll location={location} />
                        </Layout>
                    </>
                </HelmetAndGlobalContext>
            )}
        </Sentry.ErrorBoundary>
    );
};

const NoActiveSession = ({ context }: Props) => {
    const isClient = useIsClient();
    const location = useLocation();

    return isClient ? (
        <HelmetAndGlobalContext context={context}>{renderNotAuthenticatedBasedOnPath(location)}</HelmetAndGlobalContext>
    ) : null;
};

export { HasLoggedInSession, NoActiveSession };
