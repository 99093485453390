/* eslint-disable no-nested-ternary */
import React from 'react';
import * as Sentry from '@sentry/react';

import { useSelector } from 'react-redux';

import { selectors as iuaSelectors } from 'state/iua';

import { HasLoggedInSession, NoActiveSession } from './AuthComponentSelector';

Sentry.init({
    dsn: 'https://ba050f2443304eb19a3fc25d3692ac75@o64130.ingest.sentry.io/5719813',

    // While you're testing, we recommend that you set replaysSessionSampleRate to 1.0.
    // This ensures that every user session will be sent to Sentry.
    // Once testing is complete, we recommend lowering this value in production
    replaysSessionSampleRate: 0.1,
    // We still recommend keeping replaysOnErrorSampleRate set to 1.0.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()]
});

const App = ({ context }) => {
    const isAuthenticated = useSelector(state => iuaSelectors.getIsAuthenticated(state));

    /**
     * If user has an active session
     */
    if (isAuthenticated) {
        return <HasLoggedInSession context={context} />;
    }
    /**
     * If user has no active session
     */
    return <NoActiveSession context={context} />;
};

export default App;
