import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { RootState } from 'state/types';
import { selectors as dealerSelectors } from 'state/dealer';

import Header from 'components/Header';
import Footer from 'components/Footer';
import AdminAlert from 'components/AdminAlert';
import { useNotifications } from 'components/AdminAlert/state';

import CustomerService from 'features/customerservice';

import Toast from '../Toast';

interface Props {
    children: React.ReactNode;
}

const Layout = (props: Props) => {
    const [isClient, setIsClient] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // This code will run only on the client, after the component has mounted
        // Helps to prevent the server side not matching the client side warning
        setIsClient(true);
    }, []);

    const { children } = props;

    const { id: dealerId } = useSelector((reduxState: RootState) => dealerSelectors.getDealerApiData(reduxState));

    const { showSidePanel } = useSelector((state: RootState) => state.ui);

    const notifications = useNotifications(dealerId);

    return (
        <div className={classNames('main', location.pathname.replace('/', ''), showSidePanel && 'side-panel-open')}>
            <Toast />

            {isClient && notifications && <AdminAlert notifications={notifications} classNames="d-none d-xl-block" />}

            <Header />

            {isClient && notifications && (
                <AdminAlert notifications={notifications} classNames="d-xl-none notification-container-mobile " />
            )}

            <main className="main-content">{children}</main>

            <Footer />

            <CustomerService />
        </div>
    );
};

export default Layout;
