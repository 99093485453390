import apolloClient from 'core/createApolloClient/apolloClientSingleton';

import CHANGE_DEALER from 'features/iua/mutations/changeDealer';
import * as IUA_USER from './actionTypes';

export const initAuth = data => async dispatch => {
    dispatch({ type: IUA_USER.SET_AUTHENTICATED, data });
};

export const logout = () => async dispatch => {
    // can we make a call apollo server from here? because we need to delete the session token
    // and that can only be done from our node server as it is a httpOnly cookie
    dispatch({ type: IUA_USER.SET_UNAUTHENTICATED });
};

export const setUserData = data => async dispatch => {
    dispatch({ type: IUA_USER.SET_USER_DATA, data });
};

export const changeIUAMainDealer = dealerId => async () => {
    await apolloClient.mutate({
        variables: {
            dealerId
        },
        mutation: CHANGE_DEALER
    });
};

export const updateChecksum = checksum => async dispatch => {
    dispatch({ type: IUA_USER.UPDATE_CHECKSUM, checksum });
};
