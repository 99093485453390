import { onError } from '@apollo/client/link/error';

import * as IUA_USER from 'state/iua/actionTypes';

// Error link factory that accepts Redux store so we can dispatch actions
const createErrorLink = store =>
    onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(error => {
                // Handle 401 Unauthorized from GraphQL
                if (error?.extensions?.errorCode === 401) {
                    store.dispatch({
                        type: IUA_USER.SET_UNAUTHENTICATED,
                        payload: {
                            feedbackMessage: 'Din session har gått ut, vänligen logga in igen.',
                            redirectUrl: window.location.pathname + window.location.search
                        }
                    });
                }
            });
        }
    });

export default createErrorLink;
