import React from 'react';
import { Container } from '@bytbil/bootstrap-react';
import classNames from 'classnames';

interface Props {
    handleDismiss: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    fixedBottom?: boolean;
}

const SchibstedStickyFooter = ({ handleDismiss, fixedBottom = true }: Props) => (
    <div
        className={classNames(
            'd-flex w-100 justify-content-center align-items-center schibsted-sticky-footer',
            fixedBottom && 'fixed-bottom'
        )}
    >
        <Container>
            <div className="d-flex w-100 justify-content-between align-items-center content-wrapper">
                <div className="d-flex align-items-baseline white-space-xl-nowrap">
                    <div className="d-none d-xl-flex justify-content-xl-between align-items-xl-baseline">
                        <p className="my-0 mr-1 ml-4 text">Blocket Admin är en del av Schibsted Marketplaces.</p>
                    </div>
                    <div className="my-0 text">
                        Schibsted News Media AB och Schibsted ASA är ansvariga för dina data på denna webbplats.{' '}
                        <a
                            className="read-more-link"
                            href="https://info.privacy.schibsted.com/se/personuppgifts-och-kakpolicy-schibsted-marketplaces-sverige/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Läs mer här
                        </a>
                    </div>
                </div>
                <i
                    onClick={handleDismiss}
                    className="fa fa-times close-icon d-flex justify-content-end align-items-center"
                    aria-hidden="true"
                />
            </div>
        </Container>
    </div>
);

export default SchibstedStickyFooter;
