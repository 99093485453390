import { createBrowserHistory, BrowserHistory, Location } from 'history';

// Function to create and augment the history object
const createCustomHistory = (): BrowserHistory | null => {
    if (typeof window === 'undefined') {
        return null; // Return null in SSR
    }

    const history = createBrowserHistory();

    // Augment the `customHistory` with methods required by React Router
    (history as any).createURL = (location: Location) => {
        const path = typeof location === 'string' ? location : history.createHref(location);
        return new URL(path, window.location.origin).toString();
    };

    (history as any).encodeLocation = (location: Location) => location;

    return history;
};

// Create the history object
const customHistory = createCustomHistory();

export default customHistory;
