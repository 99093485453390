import React from 'react';

import useHasActionPermission from 'core/hooks/useHasActionPermission';

import { GlobalContextProps } from 'state/types';

type Action = GlobalContextProps['iua']['permissions'][0]['action'];
type Target = GlobalContextProps['iua']['permissions'][0]['target'];

interface Props {
    action: Action;
    target: Target;
    children: React.ReactElement | null;
    showNoPermissionsMessage?: boolean;
}

const PermissionsProvider = ({ action, target, children, showNoPermissionsMessage = false }: Props) => {
    if (useHasActionPermission(action, target)) {
        return children;
    }

    if (showNoPermissionsMessage) {
        return <p className="container mt-2">Du har inte behörighet att utföra denna åtgärd.</p>;
    }

    return null;
};

export default PermissionsProvider;
