import { selectors as userSelectors } from '../state/user';
import { selectors as dealerSelectors } from '../state/dealer';

/**
 * Mapping between the legacy and new admin
 */
const menuFunctionMap = {
    // Offer
    '/offer': 'new_car',
    '/offer/car': 'new_car',
    '/offer/mc': 'new_mc',
    '/offer/transport': 'new_transport',
    '/offer/camper': 'new_camper',

    // Stock
    '/stock': 'list_cars',
    '/stock/prices': 'edit_offer',
    '/stock/movestock': 'move_cars',
    '/stock/specsheets': 'list_cars_pricepdf',
    '/stock/regnos': 'edit_regno',

    // Dealer
    // '/dealer': 'self_changeseller',
    '/dealer/finance': 'edit_finance',
    '/dealer/sellers': 'list_contacts',
    '/dealer/details': 'self_changeseller',

    // Statistics
    '/statistics/ads': 'statistics',

    // Super Admin
    '/search_sellercode': 'search_sellercode'
};

/**
 * Mapping between the legacy and new admin
 * @param {string} menuFunction - menu function in legacy admin
 * @return {string} path in new admin
 */
const menuFunctionPath = menuFunction => {
    const functionIndex = Object.keys(menuFunctionMap).indexOf(menuFunction);

    if (functionIndex >= 0) {
        return menuFunctionMap[menuFunction];
    }

    // Default to root path
    return 'home';
};

// /**
//  * Handle the POST-request from the legacy admin
//  * @param {object} req - POST request object
//  */
// export const requestHandler = (req) => {
//     const menuFunction = req.body.menu_function || '/';
//     const seller = req.body.select_seller || undefined;

//     return {
//         path: menuFunctionPath(menuFunction),
//         seller
//     };
// };

/**
 * Helper function for creating a form element
 * @param {string} name
 * @param {string} value
 */
const createFormElement = (name, value, type = 'hidden') => {
    const formElement = document.createElement('input');

    formElement.setAttribute('type', type);
    formElement.setAttribute('name', name);
    formElement.setAttribute('value', value);

    return formElement;
};

/**
 * Helper function for creating a form element to perform
 * synchronous POST requests to legacy admin
 * @param {string} name - name of the form
 * @param {string} method - POST / GET
 * @param {string} action - request target
 * @param {Array.<Object>} formElements - element specification
 * @param {object} formElement
 * @param {string} formElement.name
 * @param {string} formElement.value
 */
export const createForm = (name, method, action, formElements) => {
    const DOMForm = document.createElement('form');
    DOMForm.setAttribute('id', name);
    DOMForm.setAttribute('name', name);
    DOMForm.setAttribute('method', method);
    DOMForm.setAttribute('action', action);

    formElements.forEach(element => DOMForm.appendChild(createFormElement(element.name, element.value)));

    return DOMForm;
};

/**
 * Update form data
 * @param {*} fieldData
 * @param {*} form
 */
export const updateForm = (fieldData, form) => {
    const formElement = form;
    fieldData.forEach(({ name, value }) => {
        formElement[name][value] = value;
    });
};

/**
 * The expected payload for routes differ from that of some functions
 * @param {string} type
 */
const getFormFields = (type, param) => {
    switch (type) {
        // Navigate to edit vehicle from stock list
        case 'edit_vehicle':
            return [
                {
                    type: 'hidden',
                    name: 'list_cars_edit',
                    value: '1'
                },
                {
                    type: 'hidden',
                    name: `andra_${param}`,
                    value: 1
                }
            ];
        default:
            return undefined;
    }
};

/**
 * Navigate to legacy admin by creating and posting a form
 * @param {*} state - redux state
 * @param {*} to - path to navigat to (see menuFunctionMap)
 * @param {*} param - additional parameter (e.g. id)
 */
export const navigateTo = (state, to, routeConfig = {}) => {
    const config = window.App.config.legacyNavigation;
    /**
     * auth_login - cookie
     * auth_seller - cookie
     * menu_seller - cookie (same as auth seller)
     * auth_password - remove
     * auth_level - cookie
     */

    const { code: dealerCode } = dealerSelectors.getDealerApiData(state);

    // Some functionality requires custom fields
    const customFormFields = getFormFields(routeConfig.type, routeConfig.param);

    const userData = [
        {
            type: 'hidden',
            name: 'auth_login',
            value: userSelectors.getUser(state).login
        },
        {
            type: 'hidden',
            name: 'auth_level',
            value: userSelectors.getUser(state).level
        }
    ];

    if (typeof dealerCode !== 'undefined') {
        userData.push({
            type: 'hidden',
            name: 'auth_seller',
            value: dealerCode
        });

        userData.push({
            type: 'hidden',
            name: 'menu_seller',
            value: dealerCode
        });
    }

    if (document.form1 && document.form1.parentNode) {
        document.form1.parentNode.removeChild(document.form1);
    }

    const DOMForm = createForm(config.form.name, config.form.method, config.form.action, [
        ...(typeof customFormFields !== 'undefined'
            ? customFormFields
            : [
                  {
                      type: 'hidden',
                      name: config.form.fieldNames.route,
                      value: menuFunctionPath(to)
                  },
                  {
                      type: 'hidden',
                      name: 'menu',
                      value: '1'
                  }
              ]),
        ...userData
    ]);
    document.body.appendChild(DOMForm);
    document.form1.submit();
};

export default navigateTo;
