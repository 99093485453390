import React from 'react';
import { ApolloError } from '@apollo/client';
import { getErrorData, getCidMessage, getUiMessage, getShouldShowCid } from 'core/utils/customError';
import { ERROR_MESSAGE } from 'core/constants/common';

/**
 * Get custom error message based on ApolloError or custom status code
 * @param {ApolloError} error - ApolloError object
 * @param {string} name - Name of the error, provide if custom message is needed
 * @param {number} statusCode - Custom status code, only provide if no error object is available
 * @returns {React.ReactElement} - Custom error message with cid if available
 */

type ErrorProps = {
    error: ApolloError | undefined;
    name?: string;
    statusCode?: never;
};

type CustomProps = {
    statusCode: number;
    name?: string;
    error?: never;
};

type Props = ErrorProps | CustomProps;

const CustomError = ({ error, name, statusCode }: Props) => {
    if (statusCode) {
        return <>{getUiMessage({ errorCode: statusCode, name })}</>;
    }

    if (!error) {
        return <>{ERROR_MESSAGE.COMMON}</>;
    }

    const { correlationId, errorCode, uiMessage } = getErrorData(error);

    const uiMsg = uiMessage || getUiMessage({ errorCode, name });
    const cidMsg = getShouldShowCid(uiMsg) && getCidMessage(correlationId);

    return (
        <>
            <p className="m-0">{uiMsg}</p>
            {cidMsg && <p className="m-0 fs-1 font-weight-light">{cidMsg}</p>}
        </>
    );
};

export default CustomError;
